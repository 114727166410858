@font-face {
    font-family: "Roboto";
    src: url("../fonts/Roboto-Regular.ttf");
}

body {
    background: gray;
}

.background {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    filter: opacity(0.5);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

.content {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    width: 60%;
    font-family: 'Roboto';
    color: #dedede;
    left: 20vw;
    top: 5vh;
    position: absolute;
    line-height: 30px;
    z-index: 10;
}

.textBody {
    margin-bottom: 10px;
}

.githubIcon {
    transform: scale(1.2);
    margin-left: 4px;
    filter: invert(0.8) brightness(1.15);
}

.linkedinIcon {
    text-decoration: none;
}