.gear {
    width: 200px;
    height: 200px;
    margin: auto;
    background: #fff;
    border-radius: 50%;
    animation-name: spin;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.gearParent{
    position: relative;
    top: 150vh;
    left: 50vw;
    transform: scale(3);
    width: 100vw;
    height: 100vh;
}

.gear .center{
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 10;
  width: 100px;
  height: 100px;
  background: gray;
  border-radius: 50%;
}

.tooth{
  position: absolute;
  top: -25px;
  left: 75px;
  z-index: 1;
  width: 45px;
  height: 250px;
  background:#fff;
}

.tooth:nth-child(2){
  transform: rotate(45deg);
}

.tooth:nth-child(3){
  transform: rotate(90deg);
}

.tooth:nth-child(4){
  transform: rotate(135deg);
}

@keyframes spin {
  from {transform: rotate(0deg); }
  to {transform: rotate(360deg);}
}

