.spider {
  transition: 2s;
  position: absolute;
  display: flex;
  flex-flow: column-reverse;
  left: 7vw;
  top: 0vh;
  width: 15vw;
  height: 80vh;
}

.spider:hover{
  top: -60vh;
}

.container {
  animation: spider-anima 12s ease-out;
  animation-fill-mode: forwards;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1s;
} 

@keyframes spider-anima {
  0%  { top: -100px; }
  50% { top: 50vh; }
}

.eye {
  background-color: #fff;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}
.eye-right {
  right: 6px;
}
.eye-left {
  left: 6px;
}

.spider-web {
  animation: spider-web-anima 6s ease-out;
  animation-fill-mode: forwards;
  background-color: #000;
  border-radius: 4px;
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 2px;
  transition: 1s;
}

@keyframes spider-web-anima {
  0%    { height: 0; top: -100px; }
  100%  { height: 50vh; top: 0;}
}

.spider-body {
  background-color: #000;
  border-radius: 50%;
  height: 20px;
  left: 50%;  
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  transition: 1s;
}   
.spider {
  transition: 2s top;
  position: absolute;
  display: flex;
  flex-flow: column-reverse;
  left: 7vw;
  top: 0vh;
  width: 15vw;
  height: 100vh;
}

.spider:hover{
  top: -60vh;
}

.container {
  animation: spider-anima 12s ease-out;
  animation-fill-mode: forwards;
  position: absolute;
  top: 50vh;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 1s;
} 

@keyframes spider-anima {
  0%  { top: -100px; }
  50% { top: 50vh; }
}

.eye {
  background-color: #fff;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
}
.eye-right {
  right: 6px;
}
.eye-left {
  left: 6px;
}

.spider-web {
  animation: spider-web-anima 6s ease-out;
  animation-fill-mode: forwards;
  background-color: #000;
  border-radius: 4px;
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 2px;
  transition: 1s;
}

@keyframes spider-web-anima {
  0%    { height: 0; top: -100px; }
  100%  { height: 50vh; top: 0;}
}

.spider-body {
  background-color: #000;
  border-radius: 50%;
  height: 20px;
  left: 50%;  
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  transition: 1s;
}   

.arms{
  display: inline-flex;
  position: relative;
}

.arm{
  height: 16px;
  width: 16px;
  border-top: 2px solid black;
  border-radius: 1em;
  position: relative;
  top: 3px;
  left: 0px;
  margin-top: -13px;
}

.arm-container{
  display: inline-flex;
  flex-flow: column-reverse;
  position: relative;
  top: 11px;
}
.arms{
  display: inline-flex;
  position: relative;
}

.arm{
  height: 16px;
  width: 16px;
  border-top: 2px solid black;
  border-radius: 1em;
  position: relative;
  top: 3px;
  left: 0px;
  margin-top: -13px;
}

.arm-container{
  display: inline-flex;
  flex-flow: column-reverse;
  position: relative;
  top: 11px;
}